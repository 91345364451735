// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marine-container {
    text-align: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: background 0.3s ease; /* Transition douce du niveau de la mer */
    background-repeat: no-repeat;

  }
  
  @keyframes wave-animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200px 0; /* Déplacement des vagues */
    }
  }
  
  h1 {
    font-family: "Arial", sans-serif;
    color: #003366;
    text-shadow: 0px 1px 3px rgba(255, 255, 255, 0.7);
  }
  
  .para {
    font-size: 18px;
    font-family: "Arial", sans-serif;
    color: #003366;
  }
  
  .marine-button {
    background-color: #00bfff;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    color: white;
    transition: transform 0.2s ease;
  }
  
  .marine-button:hover {
    transform: scale(1.1);
    background-color: #0077b3;
  }
  
  .game-over {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/dossier/cookie.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,gCAAgC,EAAE,yCAAyC;IAC3E,4BAA4B;;EAE9B;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,6BAA6B,EAAE,2BAA2B;IAC5D;EACF;;EAEA;IACE,gCAAgC;IAChC,cAAc;IACd,iDAAiD;EACnD;;EAEA;IACE,eAAe;IACf,gCAAgC;IAChC,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,+BAA+B;EACjC;;EAEA;IACE,qBAAqB;IACrB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".marine-container {\n    text-align: center;\n    height: 100vh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    overflow: hidden;\n    transition: background 0.3s ease; /* Transition douce du niveau de la mer */\n    background-repeat: no-repeat;\n\n  }\n  \n  @keyframes wave-animation {\n    0% {\n      background-position: 0 0;\n    }\n    100% {\n      background-position: -200px 0; /* Déplacement des vagues */\n    }\n  }\n  \n  h1 {\n    font-family: \"Arial\", sans-serif;\n    color: #003366;\n    text-shadow: 0px 1px 3px rgba(255, 255, 255, 0.7);\n  }\n  \n  .para {\n    font-size: 18px;\n    font-family: \"Arial\", sans-serif;\n    color: #003366;\n  }\n  \n  .marine-button {\n    background-color: #00bfff;\n    border: none;\n    border-radius: 5px;\n    padding: 15px 30px;\n    font-size: 18px;\n    cursor: pointer;\n    color: white;\n    transition: transform 0.2s ease;\n  }\n  \n  .marine-button:hover {\n    transform: scale(1.1);\n    background-color: #0077b3;\n  }\n  \n  .game-over {\n    text-align: center;\n    background-color: rgba(0, 0, 0, 0.7);\n    color: white;\n    padding: 20px;\n    border-radius: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

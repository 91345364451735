import React, { useEffect, useState } from 'react';
import barilImage from '../assets/images/barilCookie1.png';
import plasticBagImage from '../assets/images/plasticBagCookie1.png';
import plasticBottleImage from '../assets/images/plasticBottleCookie1.png';
import fuelImage from '../assets/images/fuelCookie1.png';
import strawImage from '../assets/images/strawCookie1.png';
import trashImage from '../assets/images/trashCookie1.png';

const FallingObject = ({ id, x, y, type, opacity, onClick }) => {
    const [offsetX, setOffsetX] = useState(0);

    useEffect(() => {
        if (type === 'plasticBottle') {
            let time = 0;
            let animationFrame;

            const animate = () => {
                time += 0.03; // Ajustez la vitesse de l'oscillation
                setOffsetX(10 * Math.sin(time));
                animationFrame = requestAnimationFrame(animate);
            };

            animationFrame = requestAnimationFrame(animate);
            return () => cancelAnimationFrame(animationFrame);
        }
    }, [type]);

    const style = {
        position: 'absolute',
        left: `${x + offsetX}%`, // Mouvement pendulaire
        top: `${y}%`,
        width: type === 'plasticBag' ? '60px' : '60px',
        height: '60px',
        backgroundImage:
            type === 'plasticBag'
                ? `url(${plasticBagImage})`
                : type === 'plasticBottle'
                    ? `url(${plasticBottleImage})`
                    : type === 'barrel'
                        ? `url(${barilImage})`
                        : type === 'fuel'
                            ? `url(${fuelImage})`
                            : type === 'straw'
                                ? `url(${strawImage})`
                                : type === 'trash'
                                    ? `url(${trashImage})`
                                        : `url(${barilImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        opacity: opacity || 1,
        cursor: 'pointer',
        transform: `translateX(${offsetX}px)`, // Utilise transform pour fluidité
    };

    return <div style={style} onClick={() => onClick(id)} />;
};

export default FallingObject;

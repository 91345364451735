import React, { useState, useEffect } from 'react';
// import Boat from './Boat';
import FallingObject from './FallingObject';
import "./CookieClickerBryan.css";
import { giveAchievement } from "../components/achievment/achievement";
import { useNavigate } from 'react-router-dom';
import ReturnToHome from '../components/retourAceuil';

const CookieClickerBryan = () => {
    const [fallingObjects, setFallingObjects] = useState([]);
    const [score, setScore] = useState(0);
    const [lives, setLives] = useState(1000);
    const objectif = score >= 20 ;
    const objectif2 = score >= 10;
    const navigate = useNavigate();
    if (objectif){
        giveAchievement(28);
        navigate('/');
    }
    if (objectif2){
        giveAchievement(29);
    }

    const objectTypes = ['barrel', 'plasticBag', 'plasticBottle', 'straw', 'trash'];

    let mousePosition = { x: 50, y: 50 };

    useEffect(() => {
        const handleMouseMove = (e) => {
            mousePosition = {
                x: (e.clientX / window.innerWidth) * 100, // Position en pourcentage
                y: (e.clientY / window.innerHeight) * 100,
            };
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    // Génération d'objets aléatoires
    useEffect(() => {
        const interval = setInterval(() => {
            const newObject = {
                id: Date.now(),
                x: Math.random() * 90, // Position horizontale
                y: 10, // Position verticale
                type: objectTypes[Math.floor(Math.random() * objectTypes.length)], // Type aléatoire
            };
            setFallingObjects((prev) => [...prev, newObject]);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // Mise à jour des positions
    useEffect(() => {
        const interval = setInterval(() => {
            setFallingObjects((prev) =>
                prev.map((obj) => {
                    if (obj.type === 'plasticBag') {
                        // Logique d'évitement de la souris
                        const dx = mousePosition.x - obj.x;
                        const dy = mousePosition.y - obj.y;
                        const distance = Math.sqrt(dx * dx + dy * dy); // Distance entre la souris et l'objet

                        // Ajustez la vitesse horizontale en fonction de la distance
                        let speedX = 2; // Vitesse normale d'évitement
                        if (distance < 15) {
                            speedX *= distance / 15; // Ralentit en fonction de la proximité (0 à 15)
                        }

                        // Fuit horizontalement si la souris est proche
                        if (Math.abs(dx) < 15 && Math.abs(dy) < 15) {
                            return { ...obj, x: obj.x - Math.sign(dx) * speedX, y: obj.y + 2 };
                        } else {
                            return { ...obj, y: obj.y + 2 };
                        }
                    }
                    if (obj.type === 'fuel') {
                        if (obj.direction === 'up' && obj.y <= 10) {
                            return { ...obj, direction: 'down' }; // Change de direction vers le bas
                        } else if (obj.direction === 'up') {
                            return { ...obj, y: obj.y - 1 }; // Remonte
                        } else if (obj.direction === 'down') {
                            return { ...obj, y: obj.y + 0.5 }; // Descend doucement
                        }
                    }
                    if (obj.type === 'straw') {
                        const newDistance = (obj.distance || 0) + 2;

                        const currentOpacity = obj.opacity !== undefined ? obj.opacity : 1; // Définit l'opacité initiale à 1
                        const newOpacity = Math.max(0.1, 1 - obj.y / 50); // Réduit l'opacité jusqu'à 0.1 mais pas en dessous

                        // Continue de descendre avec l'opacité calculée
                        return { ...obj, y: obj.y + 2, distance: newDistance, opacity: newOpacity };
                    }

                    if (obj.type === 'trash') {
                        const trashY = obj.y + 1; // Tombe plus doucement

                        if (trashY >= 30) {
                            const spawnTypes = ['plasticBag', 'plasticBottle', 'straw'];

                            const newObjects = [
                                {
                                    id: Date.now(),
                                    x: Math.max(0, obj.x - 5),
                                    y: trashY,
                                    type: spawnTypes[Math.floor(Math.random() * spawnTypes.length)],
                                },
                                {
                                    id: Date.now() + 1,
                                    x: obj.x,
                                    y: trashY,
                                    type: spawnTypes[Math.floor(Math.random() * spawnTypes.length)],
                                },
                                {
                                    id: Date.now() + 2,
                                    x: Math.min(90, obj.x + 5),
                                    y: trashY,
                                    type: spawnTypes[Math.floor(Math.random() * spawnTypes.length)],
                                },
                            ];

                            setFallingObjects((prev) => [...prev, ...newObjects]);
                            return null;
                        }

                        return { ...obj, y: trashY }; // Continue de descendre
                    }

                    return { ...obj, y: obj.y + 2 }; // Descend normalement pour les autres types
                })
                    .filter((obj) => obj !== null)
                    .filter((obj) => {
                    if (obj.y > 100) {
                        let livesToLose = 1;

                        // Détermine combien de vies l'objet doit retirer
                        switch (obj.type) {
                            case 'plasticBag':
                                livesToLose = 3;
                                break;
                            case 'plasticBottle':
                                livesToLose = 2;
                                break;
                            case 'straw':
                                livesToLose = 1;
                                break;
                            case 'barrel':
                                livesToLose = 10;
                                break;
                            case 'fuel':
                                livesToLose = 5;
                                break;
                            default:
                                livesToLose = 1;
                                break;
                        }

                        // Réduit les vies en fonction du type
                        setLives((prevLives) => Math.max(0, prevLives - livesToLose));

                        return false;
                    }
                    return true;
                })
            );
        }, 50); // Mise à jour fréquente pour fluidité
        return () => clearInterval(interval);
    }, []);

    // Gestion des clics
    const handleClick = (id, type) => {
        if (type === 'barrel') {
            // Remplace le baril par un objet "fuel"
            setFallingObjects((prev) =>
                prev.map((obj) =>
                    obj.id === id
                        ? {
                            id: Date.now(),
                            x: obj.x,
                            y: obj.y,
                            type: 'fuel',
                            direction: 'up', // Commence à remonter
                        }
                        : obj
                )
            );
        } else if (type === 'fuel') {
            // Si "fuel", le fait remonter
            setFallingObjects((prev) =>
                prev.map((obj) =>
                    obj.id === id ? { ...obj, direction: 'up' } : obj
                )
            );
        } else {
            // Supprime l'objet pour les autres types
            setFallingObjects((prev) => prev.filter((obj) => obj.id !== id));
            setScore((prevScore) => prevScore + 1);
        }
    };

    return (
        <div className="game-board">
            <ReturnToHome />
            {/*<Boat />*/}
            {fallingObjects.map((obj) => (
                <FallingObject
                    key={obj.id}
                    id={obj.id}
                    x={obj.x}
                    y={obj.y}
                    type={obj.type} // Passe le type au composant
                    opacity={obj.opacity} // Passe l'opacité calculée
                    onClick={(id) => handleClick(id, obj.type)} // Passe l'ID et le type
                />
            ))}
            <div className="scoreboard">
                Score: {score} | Lives: {lives}
            </div>
            <div className="bottomBg">
            </div>
        </div>
    );
};

export default CookieClickerBryan;

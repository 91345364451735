// src/Logo1/App.jsx
import React from 'react';
import MovingLogo from '../components/Logo_anim1/MovingLogo';


const App_Logo1 = () => {
    return (
        <div className="app">
            <MovingLogo />
        </div>
    );
};

export default App_Logo1;

import React, { useState, useEffect } from "react";
import "./App.css";
import Bourse from "./bourse_graphe";
import Shop from "./shop";
import poubelle1 from "../../assets/images/poubelle1.png";
import poubelle2 from "../../assets/images/poubelle2.png";
import poubelle3 from "../../assets/images/poubelle3.png";
import poubelle4 from "../../assets/images/poubelle4.png";
import { giveAchievement } from "../achievment/achievement";
import { useNavigate } from 'react-router-dom';
import ReturnToHome from "../retourAceuil";

function Modal({ onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Bienvenue dans le jeu !</h2>
        <p className="textmodal">
        Sauve l'Océan:<br></br>
        Ramasse des déchets<br></br>
        Clique pour collecter des déchets polluants et accumule des points.<br></br>
        Vends tes déchets<br></br>
        Échange-les à la Bourse pour obtenir de l’argent.<br></br>
        Visite le Shop<br></br>
        Bateaux : Achète des bateaux (niveaux 1, 2, 3) pour ramasser des déchets automatiquement.<br></br>
        Collecteur manuel : Améliore ta capacité à collecter plus de déchets à chaque clic.<br></br>
        Alterne entre la Bourse pour vendre et le Shop pour améliorer, afin de nettoyer l’océan plus efficacement.
        Sauve la planète
        Nettoie l’océan, développe tes outils, et deviens un héros écologique !
      </p>
        <button onClick={onClose} className="modal-close">
          Jouer
        </button>
      </div>
    </div>
  );
}

function CoockieClickerBateau({handlePageSelect}) {
  const [showModal, setShowModal] = useState(true);
  const [clickerCounter, setClickerCounter] = useState(0);
  const [nbBateaux, setNbBateaux] = useState(0);
  const [nbDechet, setNbDechet] = useState(0);
  const [dechetActif, setDechetActif] = useState(false);
  const [dechetRamasserParSeconde, setDechetRamasserParSeconde] = useState(0);
  const [argentTotal, setArgentTotal] = useState(0);
  const [prixBateau, setPrixBateau] = useState(15);
  const [nbBateauxN2, setNbBateaux2] = useState(0);
  const [prixBateau2, setPrixBateau2] = useState(150);
  const [nbBateauxN3, setNbBateaux3] = useState(0);
  const [prixBateau3, setPrixBateau3] = useState(1000);
  const [objectifDechets, setObjectifDechets] = useState(100000);
  const [prixAction, setPrixAction] = useState(0);
  const [filetPecheBateaux, setFiletPecheBateaux] = useState(0);
  const [prixFiletPeche, setPrixFiletPeche] = useState(20);
  const [niveauFiletManuel, setNiveauFiletManuel] = useState(0);
  const [prixFiletManuel, setPrixFiletManuel] = useState(500);

  const [portActif, setPortActif] = useState(false);  // Pour savoir si le port est activé
  const [prixPort, setPrixPort] = useState(20000);  // Le prix du port

  useEffect(() => {
    const timer = setTimeout(() => setShowModal(false), 30000);
    return () => clearTimeout(timer);
  }, []);

  // Créer un bateau Niveau 1
  const creerBateau = () => {
    if (argentTotal >= prixBateau) {
      setNbBateaux((prev) => prev + 1);
      setArgentTotal((prev) => Math.round((prev - prixBateau) * 100) / 100);
      setPrixBateau((prev) => Math.round(prev * 1.3 * 100) / 100); // Ajustement
    } else {
      alert("Vous n'avez pas assez d'argent.");
    }
  };

  // Créer un bateau Niveau 2
  const creerBateauN2 = () => {
    if (argentTotal >= prixBateau2) {
      setNbBateaux2((prev) => prev + 1);
      setArgentTotal((prev) => Math.round((prev - prixBateau2) * 100) / 100);
      setPrixBateau2((prev) => Math.round(prev * 1.5 * 100) / 100); // Ajustement
    } else {
      alert("Vous n'avez pas assez d'argent.");
    }
  };

  // Créer un bateau Niveau 3
  const creerBateauN3 = () => {
    if (argentTotal >= prixBateau3) {
      setNbBateaux3((prev) => prev + 1);
      setArgentTotal((prev) => Math.round((prev - prixBateau3) * 100) / 100);
      setPrixBateau3((prev) => Math.round(prev * 1.8 * 100) / 100); // Ajustement
    } else {
      alert("Vous n'avez pas assez d'argent.");
    }
  };

  const creerDechet = () => {
    const dechetAjoute =
      niveauFiletManuel === 0
        ? 1
        : niveauFiletManuel === 1
        ? 50
        : niveauFiletManuel === 2
        ? 150
        : niveauFiletManuel === 3
        ? 500
        : 0;
    setClickerCounter(clickerCounter+1);
    setNbDechet((prev) => prev + dechetAjoute);
  };

  const ameliorerFiletManuel = () => {
    if (argentTotal >= prixFiletManuel) {
      if (niveauFiletManuel < 3) {
        setNiveauFiletManuel((prev) => prev + 1);
        setArgentTotal((prev) => prev - prixFiletManuel);
        setPrixFiletManuel((prev) => Math.round(prev * 5 * 100) / 100);
      } else {
        alert("Vous avez atteint le niveau maximum du filet manuel.");
      }
    } else {
      alert("Vous n'avez pas assez d'argent.");
    }
  };

  const vendreDechet = () => {
    if (nbDechet >= 0) {
      const gain = Math.round(nbDechet * prixAction * 100) / 100;
      setNbDechet(0);
      setArgentTotal((prev) => Math.round((prev + gain) * 100) / 100);
    } else {
      alert("Vous n'avez pas assez de déchets pour vendre.");
    }
  };

  const ameliorerFilet = () => {
    if (argentTotal >= prixFiletPeche) {
      if (filetPecheBateaux < 10) {
        setFiletPecheBateaux((prev) => prev + 1);
        setArgentTotal((prev) => Math.round((prev - prixFiletPeche) * 100) / 100);
        setPrixFiletPeche((prev) => Math.round(prev * 1.2 * 100) / 100);
      } else {
        alert("Vous avez atteint la limite de filets.");
      }
    } else {
      alert("Vous n'avez pas assez d'argent.");
    }
  };

  // Fonction pour activer le port
  const activerPort = () => {
    if (argentTotal >= prixPort) {
      if (!dechetActif){
        setPortActif(true);
        setArgentTotal((prev) => Math.round((prev - prixPort) * 100) / 100);
      }else{
        alert("Vous avez déja activer le port.");
      }
    } else {
      alert("Vous n'avez pas assez d'argent pour activer le port.");
    }
  };

  const getImageForLevel = () => {
    switch (niveauFiletManuel) {
      case '0':
        return poubelle2;  // Remplacez par le chemin de l'image pour le niveau 0
      case 1:
        return poubelle3;  // Remplacez par le chemin de l'image pour le niveau 1
      case 2:
        return poubelle4;  // Remplacez par le chemin de l'image pour le niveau 2
      case 3:
        return poubelle1;  // Remplacez par le chemin de l'image pour le niveau 3
      default:
        return poubelle2;  // Image par défaut
    }
  };

  useEffect(() => {
    const totalRamasser =
      nbBateaux + nbBateauxN2 * 15 + nbBateauxN3 * 50; // Ajout des bateaux N3
    const ramassage = totalRamasser * (1 + 0.1 * filetPecheBateaux);
    // Si le port est activé, multiplier les déchets ramassés par 4
    setDechetRamasserParSeconde(Math.round(ramassage * (portActif ? 4 : 1) * 100) / 100);
    setDechetActif(totalRamasser > 0);
  }, [nbBateaux, nbBateauxN2, nbBateauxN3, filetPecheBateaux, portActif]);

  useEffect(() => {
    if (dechetActif) {
      const interval = setInterval(() => {
        setNbDechet((prev) =>
          Math.round((prev + dechetRamasserParSeconde) * 100) / 100
        );
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [dechetActif, dechetRamasserParSeconde]);

  const navigate = useNavigate();
  const objectifAtteint = nbDechet >= objectifDechets;
  const click5 = clickerCounter >= 10;
  if(click5){
    giveAchievement(5);
  }
  const click50 = clickerCounter >= 50;
  if(click50){
    giveAchievement(14);
  }
  const click100 = clickerCounter >= 100;
  if(click100){
    giveAchievement(6);
  }
  if(objectifAtteint){
    giveAchievement(27);
    navigate('/');
  }
  const pourcentageProgression = (nbDechet / objectifDechets) * 100;

  return (
    <div className="App-bateau">
      <header className="header">
        <div className="header-item">
          <span role="img" aria-label="dechet">♻️</span> Déchets : {nbDechet}
        </div>
        <div className="progress-bar-container">
          <p>Progression vers l'objectif : {nbDechet}/{objectifDechets}</p>
          <div className="progress-bar-wrapper">
            <progress value={nbDechet} max={objectifDechets} className="progress-bar" />
            <span className="progress-text">{Math.round(pourcentageProgression)}%</span>
          </div>
        </div>
        <div className="header-item">
          <span role="img" aria-label="argent">💰</span> Argent : {argentTotal}€
        </div>
        <ReturnToHome/>
      </header>

      <div className="App-body-bateau">
        <Shop
          creerBateau = {creerBateau}
          creerBateauN2 = {creerBateauN2}
          creerBateauN3 = {creerBateauN3}
          prixBateau={prixBateau}
          prixBateau2={prixBateau2}
          prixBateau3={prixBateau3}
          nbBateaux={nbBateaux}
          nbBateauxN2={nbBateauxN2}
          nbBateauxN3={nbBateauxN3}
          ameliorerFilet={ameliorerFilet}
          ameliorerFiletManuel={ameliorerFiletManuel}
          activerPort={activerPort}
          filetPecheBateaux={filetPecheBateaux}
          prixFiletPeche={prixFiletPeche}
          niveauFiletManuel={niveauFiletManuel}
          prixFiletManuel={prixFiletManuel}
          prixPort={prixPort}
          dechetActif={dechetActif}
        />

        <div className="actions">
          {showModal && <Modal onClose={() => setShowModal(false)} />}
          <button onClick={creerDechet} className="button get-dechet">
            <img src={poubelle1} alt="poubelle"></img>
          </button>

          <button onClick={vendreDechet} className="button sell-dechet">
            ♻️
          </button>
        </div>

        <div className="stats">
          <h2 className="stats-title">Statistiques</h2>

          <div className="stats-section">
            <h3 className="stats-subtitle">Déchets</h3>
            <p><strong>Déchets par seconde :</strong> {dechetRamasserParSeconde}</p>
            <p><strong>Déchets totaux :</strong> {nbDechet}</p>
          </div>

          <div className="stats-section">
            <h3 className="stats-subtitle">Bateaux</h3>
            <p><strong>Bateaux Niveau 1 :</strong> {nbBateaux}</p>
            <p><strong>Bateaux Niveau 2 :</strong> {nbBateauxN2}</p>
            <p><strong>Bateaux Niveau 3 :</strong> {nbBateauxN3}</p>
          </div>

          <div className="stats-section">
            <h3 className="stats-subtitle">Argent et Objectifs</h3>
            <p><strong>Argent total :</strong> {argentTotal} €</p>
            <p><strong>Objectif de déchets :</strong> {objectifDechets}</p>
          </div>
        <Bourse setPrixAction={setPrixAction} vendreDechet={vendreDechet} argentTotal={argentTotal} setArgentTotal={setArgentTotal} />
        </div>

      </div>
    </div>
  );
}

export default CoockieClickerBateau;

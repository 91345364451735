import React, { useState } from 'react';
import './achievement.css';

const closeAllModals = () => {
    const modals = document.querySelectorAll('.modalAchievement');
    modals.forEach(modal => {
        if (!modal.classList.contains('hidden')) {
            modal.classList.add('hidden');
        }
    });
};

const toggleHotspotsVisibility = (visible) => {
    const hotspots = document.querySelectorAll('.hotspot');
    hotspots.forEach(hotspot => {
        hotspot.style.display = visible ? 'block' : 'none';
    });
};

const getUnlockedAchievements = () => {
    let achievements = localStorage.getItem('achievements');
    if (!achievements) {
        achievements = [];
    } else {
        achievements = JSON.parse(achievements);
    }
    return achievements;
};

const getAchievements = async () => {
    try {
        const response = await fetch('/achievement/dataAchievment.json');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const json = await response.json();
        return json.achievment; // Ensure to return the array
    } catch (error) {
        console.error('Failed to fetch achievements:', error);
        return [];
    }
};

const playSound = (soundUrl) => {
    const audio = new Audio(soundUrl);
    audio.play().catch(error => {
        console.error('Audio playback failed:', error);
    });
};

const displayAchievement = async (achievement) => {
    const container = document.getElementById('achievementContainer');
    container.innerHTML = ''; // Clear the container

    const imageUrl = `${process.env.PUBLIC_URL}/achievement/icon.png`;
    const achievementElement = document.createElement('div');
    achievementElement.className = 'achievement';
    achievementElement.innerHTML = `
        <img class="achievementImage" src="${imageUrl}">
        <span class="achievementSeparator"></span>
        <div class="achievementText">
            <p class="achievementTitle"><strong>${achievement.title}</strong></p>
            <p class="achievementDescription">${achievement.description}</p>
        </div>
    `;
    container.appendChild(achievementElement);

    playSound(`${process.env.PUBLIC_URL}/achievement/achievement.mp3`);

    container.addEventListener('click', () => {
        achievementElement.classList.add('fade-out');
        setTimeout(() => {
            if (container.contains(achievementElement)) {
                container.removeChild(achievementElement);
            }
        }, 1000); // Match the duration of the fade-out animation
    });

    // Set a timeout to remove the achievement after 20 seconds
    setTimeout(() => {
        achievementElement.classList.add('fade-out');
        setTimeout(() => {
            if (container.contains(achievementElement)) {
                container.removeChild(achievementElement);
            }
        }, 1000); // Match the duration of the fade-out animation
    }, 10000);
};

const openModalAchievement = async () => {
    closeAllModals();
    toggleHotspotsVisibility(false);

    const modal = document.getElementById('achievementModal');
    modal.classList.toggle('hidden');
    modal.innerHTML = `
        <button class="closeButton" id="closeAchievementModal">X</button>
        <h2 style="text-align: center; font-size: xx-large">Succès débloquée</h2>
        <div id="achievementList_Unlocked"></div>
        <h2 style="text-align: center; font-size: xx-large">Succès non-débloquée</h2>
        <div id="achievementList_Locked"></div>`;

    document.getElementById('closeAchievementModal').addEventListener('click', () => {
        closeAllModals();
        toggleHotspotsVisibility(true);
    });

    const achievementListUnlocked = document.getElementById('achievementList_Unlocked');
    const achievementListLocked = document.getElementById('achievementList_Locked');
    const unlockedAchievements = getUnlockedAchievements();
    const allAchievements = await getAchievements();

    // Display unlocked achievements
    for (const achievementID of unlockedAchievements) {
        const achievement = allAchievements.find(a => a.id === achievementID);
        if (achievement) {
            const achievementElement = document.createElement('div');
            achievementElement.className = 'achievementItem';
            achievementElement.innerHTML = `
                <p><strong>${achievement.title}</strong></p>
                <p>${achievement.description}</p>
            `;
            achievementListUnlocked.appendChild(achievementElement);
        }
    }

    // Display locked achievements
    const lockedAchievements = allAchievements.filter(a => !unlockedAchievements.includes(a.id));
    for (const achievement of lockedAchievements) {
        const achievementElement = document.createElement('div');
        achievementElement.className = 'achievementItem';
        achievementElement.innerHTML = `
            <p><strong>${achievement.title}</strong></p>
            <p>${achievement.description}</p>
        `;
        achievementListLocked.appendChild(achievementElement);
    }
};

const openModalPodcast = async () => {
    const unlockedAchievements = getUnlockedAchievements();
    console.log(`Unlocked achievements: ${unlockedAchievements.length}`);

    closeAllModals();
    toggleHotspotsVisibility(false);

    const modal = document.getElementById('podcastModal');
    modal.classList.toggle('hidden');
    modal.innerHTML = `
        <button class="closeButton" id="closePodcastModal">X</button>
        <h2 style="text-align: center; font-size: xx-large">Podcast</h2>
        <div id="podcastList">
            <div class="podcastItem">
                <p>FLORIAN SEVELEC</p>
                <video id="podcastVideo" width="320" height="240" controls>
                    <source src="/podcasts/PodcastEp1.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
            <div class="podcastItem">
                <p>FREDERIC LE MOIGNE</p>
                 <video id="podcastVideo" width="320" height="240" controls>
                    <source src="/podcasts/PodcastEp2.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>`;

    document.getElementById('closePodcastModal').addEventListener('click', () => {
        closeAllModals();
        toggleHotspotsVisibility(true);
    });
};

const Achievement = () => {

    return (
        <React.Fragment>
            <div id="achievementContainer"></div>
            <div id="achievementModal" className="modalAchievement hidden"></div>
            <div id="podcastModal" className="modalAchievement hidden"></div>
            <button id="buttonOpenModal" onClick={openModalAchievement}>Succès</button>
            <button
                id="buttonOpenPodcast"
                onClick={openModalPodcast}
            >
                Podcast
            </button>
        </React.Fragment>
    );
};

export const giveAchievement = async (achievementId) => {
    const achievements = getUnlockedAchievements();
    console.log(achievements);

    if (achievements.includes(achievementId)) {
        return;
    }

    const data = await getAchievements();
    console.log(data);
    const achievement = data.find(a => a.id === achievementId);
    if (!achievement) {
        console.error(`Achievement not found: ${achievementId}`);
        return;
    }

    console.log(achievement);

    await displayAchievement(achievement);
    achievements.push(achievementId);
    localStorage.setItem('achievements', JSON.stringify(achievements));
};
export default Achievement;
import React, { useState } from "react";
import "./shop.css";

function Shop({
  creerBateau,
  creerBateauN2,
  creerBateauN3,
  prixBateau,
  prixBateau2,
  prixBateau3,
  nbBateaux,
  nbBateauxN2,
  nbBateauxN3,
  ameliorerFilet,
  ameliorerFiletManuel,
  activerPort,
  filetPecheBateaux,
  prixFiletPeche,
  niveauFiletManuel,
  prixFiletManuel,
  prixPort,
  dechetActif,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
  <div>
    <button onClick={toggleMenu} className="menu-button">
      {isOpen ? "Fermer la boutique" : "Ouvrir la boutique"}
    </button>

    <aside className={`menu ${isOpen ? "open" : ""}`}>
      <nav>
        <ul>
          {/* Section pour la création de bateaux */}
          <li className="section-title">Création de Bateaux</li>
          <li>
            <button onClick={creerBateau} className="toggle-button">
              Créer un bateau Niveau 1 🛥️ ({prixBateau}€)
            </button>
          </li>
          {nbBateaux >= 5 && (
            <li>
              <button onClick={creerBateauN2} className="toggle-button">
                Créer un bateau Niveau 2 🚤 - {prixBateau2}€
              </button>
            </li>
          )}
          {nbBateauxN2 >= 5 && (
            <li>
              <button onClick={creerBateauN3} className="toggle-button">
                Créer un bateau Niveau 3 🚢 - {prixBateau3}€
              </button>
            </li>
          )}

        <li className="section-title">Améliorations des Filets</li>
        <li>
          <button 
            onClick={ameliorerFilet} 
            className="toggle-button" 
            disabled={filetPecheBateaux >= 10}  
          >
            Filet de pêche {filetPecheBateaux} / 10 - {prixFiletPeche}€
          </button>
        </li>
        <li>
          <button 
            onClick={ameliorerFiletManuel} 
            className="toggle-button" 
            disabled={niveauFiletManuel >= 3}  
          >
            Améliorer la collecte (Niveau {niveauFiletManuel} / 3) - {prixFiletManuel}€
          </button>
        </li>

        <li className="section-title">Gestion du Port</li>
        <li>
          <button 
            onClick={activerPort} 
            className="toggle-button" 
            disabled={dechetActif}  
          >
            Activer le port - {prixPort}€
          </button>
        </li>

        </ul>
      </nav>
    </aside>
  </div>

  );
}

export default Shop;

import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MeshStandardMaterial } from 'three';

export function TrashBag(props) {
    const trashRef = useRef();
    const obj = useLoader(OBJLoader, '/models/trash_bag.obj');

    obj.traverse((child) => {
        if (child.isMesh) {
            child.material = new MeshStandardMaterial({
                color: 'black',
                roughness: 0.8,
                metalness: 0.1,
                envMapIntensity: 0.5,
                transparent: true,
                opacity: 0.9
            });
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    return (
        <primitive
            ref={trashRef}
            object={obj}
            scale={[0.8, 0.8, 0.8]}
            position={[2, -1.25, 0]}
            rotation={[Math.PI / 16, Math.PI / 4, 0]}
            onPointerOver={(e) => {
                document.body.style.cursor = 'pointer';
            }}
            onPointerOut={(e) => {
                document.body.style.cursor = 'auto';
            }}
            {...props}
        />
    );
}
import React, { useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export default function Moai(props) {
    // Utilisation du hook useGLTF pour charger le modèle .glb
    const { scene } = useGLTF('/models/moai_statue.glb');

    // Variables pour contrôler l'oscillation subtile
    const floatSpeed = 0.6; // Vitesse de flottement plus lente
    const floatAmplitude = 0.01; // Amplitude du flottement réduite pour des mouvements subtils

    // Utilisation du hook useEffect pour configurer la statue initiale
    useEffect(() => {
        scene.scale.set(1, 1, 1);  // Taille d'origine de la statue
        scene.position.set(2, 0, 0);  // Position de la statue à la surface de l'eau
        scene.rotation.y = Math.PI / 2;  // Rotation de 90° (vers la droite)
    }, [scene]);

    // Effet de flottement subtil
    useFrame(() => {
        // Oscillation sur l'axe Y avec une faible amplitude et vitesse plus lente
        scene.position.y = 0 + Math.sin(Date.now() * 0.004 * floatSpeed) * floatAmplitude;
    });

    return <primitive object={scene} {...props} />;
}

import React, { useState, useEffect } from "react";

function AutoSell({ bourseValue, vendreDechet }) {
  const [inputValue, setInputValue] = useState(0.5);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setInputValue(value);
      setErrorMessage(""); // Réinitialiser l'erreur si la valeur est valide
    } else {
      setErrorMessage("La valeur doit être positive.");
    }
  };

  useEffect(() => {
    if (bourseValue >= inputValue) {
      vendreDechet(); // Appeler la fonction pour vendre les déchets
    }
  }, [bourseValue, inputValue, vendreDechet]);

  return (
    <div className="auto-sell">
      <h3>Auto Sell</h3>
      <input
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Entrez la valeur pour vendre"
      />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <p>La bourse est à : {bourseValue}€</p>
    </div>
  );
}

export default AutoSell;

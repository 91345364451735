import React, { Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import { Human } from './Human';
import { Water } from './Water';
import backgroundImage from '../../assets/images/sky.jpg';
import Moai from './Moai';
import { TrashBag } from './TrashBag';
import { ModalPoumons } from '../ModalPoumons';
import { ModalCoeur } from '../ModalCoeur';
import { ModalReins } from '../ModalReins';
import Loader from '../Loader';  // Importation du loader avec le spinner

const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
};

export default function Scene3D() {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedHotspot, setSelectedHotspot] = useState(null);

    const handleHotspotClick = (hotspot) => {
        setSelectedHotspot(hotspot);
        setModalOpen(hotspot.name);
    };

    const closeModal = () => {
        setModalOpen(null); // Ferme toutes les modales
    };

    return (
        <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
            <div style={backgroundStyle}></div>
            <Canvas camera={{ position: [0, 1, 4], fov: 50 }}>
                <Suspense fallback={<Loader />}>
                    <directionalLight position={[5, 5, 5]} intensity={1.2} castShadow />
                    <pointLight position={[-5, 5, -5]} intensity={0.8} />
                    <spotLight position={[0, 10, 0]} intensity={0.6} angle={0.5} penumbra={1} />
                    <Moai />
                    <Human onHotspotClick={handleHotspotClick} position={[0, 4.5, 0]} scale={[0.4, 0.4, 0.4]} />
                    <ambientLight intensity={1.5} />
                    <directionalLight position={[10, 10, 5]} intensity={1.5} />
                    <Water />
                    <TrashBag />
                    <OrbitControls
                        enablePan={true}
                        enableZoom={false}
                        enableRotate={true}
                        minAzimuthAngle={-Math.PI / 12}
                        maxAzimuthAngle={Math.PI / 12}
                    />
                    <Environment preset="sunset" intensity={0.5} />
                </Suspense>
            </Canvas>

            {modalOpen === "Poumons" && (
                <ModalPoumons isOpen={true} onClose={closeModal} title="Poumons" />
            )}
            {modalOpen === "Cœur" && (
                <ModalCoeur isOpen={true} onClose={closeModal} title="Cœur" />
            )}
            {modalOpen === "Reins" && (
                <ModalReins isOpen={true} onClose={closeModal} title="Reins" />
            )}
        </div>
    );
}
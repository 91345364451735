import React, { useEffect, useState } from "react";
import MovingLogo from "./Logo_anim1/MovingLogo";
import Logo from "./LogoComponents/LogoComp";

const RandomGameLogo = () => {
  const [selectedGameIndex, setSelectedGameIndex] = useState(1); // Index du jeu actuel, initialisé pour afficher <Logo />
  const [isGameActive, setIsGameActive] = useState(true); // Lancer avec le premier jeu actif
  const [isTransitioning, setIsTransitioning] = useState(false); // Indique si une transition est en cours
  const games = [MovingLogo, Logo]; // Liste des composants de jeux

  useEffect(() => {
    if (selectedGameIndex === 1) {
      const timer = setTimeout(() => startGame(), 10000); // Attendre 10 secondes avant de lancer le prochain jeu
      return () => clearTimeout(timer);
    }
  }, [selectedGameIndex]);

  const startGame = () => {
    if (isTransitioning) return; // Empêche le démarrage d'un nouveau jeu pendant une transition

    const randomIndex = Math.floor(Math.random() * games.length); // Choisir un jeu aléatoire
    setSelectedGameIndex(randomIndex);
    setIsGameActive(true);
    setIsTransitioning(false);
  };

  const endGame = () => {
    setIsGameActive(false);
    setIsTransitioning(true); // Marquer une transition en cours

    // Alterner ou relancer un jeu
    const nextGameIndex = (selectedGameIndex + 1) % games.length;
    setTimeout(() => {
      setSelectedGameIndex(nextGameIndex);
      setIsGameActive(true);
      setIsTransitioning(false); // Transition terminée
    }, 5000); // Pause avant le prochain jeu
  };

  // Composant du jeu actuel
  const CurrentGame = selectedGameIndex !== null ? games[selectedGameIndex] : null;

  return (
    <div>
      {isGameActive && CurrentGame ? (
        <CurrentGame onGameEnd={endGame} />
      ) : (
        console.log("Jeu en lancement... 🎮")
      )}
    </div>
  );
};

export default RandomGameLogo;
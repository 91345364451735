import React from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'flex-start', // Aligne la modale à gauche
        alignItems: 'center',
        zIndex: 1000
    },
    modalContent: {
        background: 'linear-gradient(145deg, #2a2a2a, #3a3a3a)',
        borderRadius: '15px',
        padding: '30px',
        width: '300px', // Réduction de la largeur pour un affichage latéral
        marginLeft: '20px', // Ajout d'un espace par rapport au bord gauche
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5)'
    },
    modalHeader: {
        marginBottom: '20px'
    },
    title: {
        color: '#FFD700', // Couleur dorée pour le titre
        margin: 0,
        fontSize: '24px',
        textAlign: 'center' // Centre le texte du titre
    },
    explanation: {
        color: '#FFD700', // Même couleur pour le texte
        fontSize: '16px',
        lineHeight: '1.5',
        marginTop: '15px',
        textAlign: 'justify'
    },
    closeButton: {
        background: 'none',
        border: 'none',
        color: '#FFD700',
        fontSize: '24px',
        cursor: 'pointer',
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    playButton: {
        background: 'linear-gradient(145deg, #4CAF50, #45a049)',
        color: 'white',
        border: 'none',
        padding: '15px 30px',
        borderRadius: '25px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'transform 0.2s'
    }
};

export function ModalCoeur({ isOpen, onClose, title }) {
    const navigate = useNavigate();
    if (!isOpen) return null;

    const handlePlayNow = () => {
        onClose();
        navigate('/coeur');
    };

    return createPortal(
        <div style={styles.modalOverlay}>
            <div style={styles.modalContent}>
                <div style={styles.modalHeader}>
                    <h2 style={styles.title}>{title}</h2>
                    <p style={styles.explanation}>
                        Marine Clicker est un jeu React où le joueur doit remplir un écran d’eau en 60 secondes en
                        cliquant sur un bouton représentant un courant marin. Chaque clic correspond à un battement
                        cardiaque (75 BPM), et le joueur doit suivre ce rythme pour atteindre 100% du niveau de la mer
                        avant la fin du chronomètre.</p>
                    <button style={styles.closeButton} onClick={onClose}>×</button>
                </div>
                <button
                    style={styles.playButton}
                    onClick={handlePlayNow}
                    onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                    onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                    Jouer maintenant
                </button>
            </div>
        </div>,
        document.body
    );
}
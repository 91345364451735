import React from 'react';
import { Html } from '@react-three/drei';
import { Rings } from 'react-loader-spinner'; // Importation du composant Rings

// Composant Loader avec un spinner animé et un texte de chargement
const Loader = () => (
    <Html center>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center', // Centrer également le texte horizontalement
            position: 'absolute', // Assurer un bon positionnement absolu pour bien centrer
            top: '50%',  // Centrer verticalement par rapport à l'écran
            left: '50%', // Centrer horizontalement
            transform: 'translate(-50%, -50%)' // Utiliser la méthode de transformation pour centrer précisément
        }}>
            <Rings color="#00bfff" height={200} width={200} /> {/* Spinner de taille ajustée */}
            <p style={{
                marginTop: '10px', // Espacement entre le spinner et le texte
                fontSize: '24px',
                color: '#fff',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '2px',
                textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)'
            }}>
                Chargement...
            </p>
        </div>
    </Html>
);

export default Loader;

import React, { useState, useEffect } from 'react';
import './App.css';
import App_Logo1 from './Logo1/App_Logo1';
import Home from './components/Home';
import CoockieClickerBateau from './components/Coockie_clicker_bateau/Cookie_cliquer_bateau'
import Scene3D from './components/3d/Scene3D';
import Achievement, { giveAchievement } from './components/achievment/achievement.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CookieClickerBryan from "./components/CookieClickerBryan";
import RandomGameLogo from './components/RandomGameLogo';
import MarineClicker from "./components/dossier/cookie";
import ReturnToHome from './components/retourAceuil.js';

function App() {
    useEffect(() => {
        giveAchievement(1); // Déclenche un succès spécifique.
    }, []);

    return (
        <Router>
            <div className="App">
                <Achievement />
                <div className="App-body">
                    <Routes>
                        <Route path="/" element={<Scene3D />} />
                        <Route path="/poumons" element={<CookieClickerBryan />} />
                        <Route path="/cd-logo" element={<App_Logo1 />} />
                        <Route path="/coeur" element={<MarineClicker />} />
                        <Route path='/reins' element={<CoockieClickerBateau/>} />
                    </Routes>
                </div>
                <RandomGameLogo />
            </div>
        </Router>
    );
}

export default App;

import React, { useState } from "react";
import { motion } from "framer-motion";
import LogoLyreco from "../../assets/images/Lyreco-logo.jpg";

const getRandomPosition = () => {
  const padding = 100;
  const x = Math.random() * (window.innerWidth - padding * 2) + padding;
  const y = Math.random() * (window.innerHeight - padding * 2) + padding;
  return { x, y };
};

const getRandomHeight = () => {
  return Math.floor(Math.random() * (150 - 50 + 1)) + 25;
};

const Logo = () => {
  const [fleeCount, setFleeCount] = useState(0);
  const [position, setPosition] = useState({ x: window.innerWidth / 2 - 50, y: window.innerHeight / 2 - 50 });
  const [isClickable, setIsClickable] = useState(false);
  const [logoHeight, setLogoHeight] = useState(100);
  const [showMessage, setShowMessage] = useState(false);
  const [isSlowMode, setIsSlowMode] = useState(false);
  const [gameOver, setGameOver] = useState(false); // Etat pour savoir si le jeu est terminé

  const handleMouseEnter = () => {
    if (!isClickable) {
      const newPosition = getRandomPosition();
      const newHeight = getRandomHeight();
      setPosition(newPosition);
      setLogoHeight(newHeight);
      setFleeCount((prev) => prev + 1);

      if (fleeCount >= 20) {
        setIsClickable(true);
        setIsSlowMode(true);
      }
    } else if (isSlowMode) {
      setPosition(getRandomPosition());
    }
  };

  const handleClick = () => {
    if (isClickable) {
      setShowMessage(true);
    }
  };

  const handleMessageClose = () => {
    console.log("Jeu terminé");
    setGameOver(true); // On met le jeu en "terminé" après la fermeture du message
  };

  // Si le jeu est terminé, on cache tout
  if (gameOver) {
    return null;
  }

  return (
    <div>
      {!showMessage ? (
        <motion.div
          animate={{ x: position.x, y: position.y }}
          transition={{
            type: "spring",
            stiffness: isSlowMode ? 40 : 300,
            damping: isSlowMode ? 30 : 20,
            duration: 0.7,
          }}
          onMouseEnter={handleMouseEnter}
          onClick={handleClick}
          style={{
            width: "fit-content",
            borderRadius: 24,
            height: logoHeight,
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden",
            boxShadow: isClickable
              ? "0 0 20px rgba(0, 255, 0, 0.35)"
              : "0 0 20px rgba(0, 0, 0, 0.125)",
            cursor: "pointer",
            transition: "box-shadow 0.25s",
          }}
        >
          <img
            src={LogoLyreco}
            alt="Logo Lyreco"
            style={{
              width: "auto",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </motion.div>
      ) : (
        <div
          style={{
            width: "350px",
            height: "200px",
            border: "1px solid #0059F0",
            borderRadius: "15px",
            fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
            padding: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            style={{
              backgroundColor: "#0059F0",
              height: "25px",
              borderRadius: "15px 15px 0 0",
              color: "white",
              padding: "5px 10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>Text box</strong>
            <button
              style={{
                backgroundColor: "#DE5227",
                color: "white",
                border: "none",
                borderRadius: "15%",
                fontSize: "15px",
                cursor: "pointer",
              }}
              onClick={handleMessageClose} // Ferme le message et termine le jeu
            >
              <strong>X</strong>
            </button>
          </div>
          <div style={{ padding: "10px" }}>
            <p>Périlleux n&apos;est-ce pas ?</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logo;
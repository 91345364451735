import React, { Suspense, useEffect, useRef } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Html, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import gsap from 'gsap';
import reinsIcon from '../../assets/images/reins.png';

const HotspotButton = ({ position, label, onClick, isImage }) => {
    return (
        <Html position={position} center>
            <div
                onClick={onClick}
                className={'hotspot'}
                style={{
                    background: 'rgba(0, 128, 255, 0.8)',
                    color: 'white',
                    padding: '8px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '20px',
                    userSelect: 'none',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {isImage ?
                    <img
                        src={label}
                        alt="icon"
                        style={{
                            width: '30px',
                            height: '30px',
                            objectFit: 'contain'
                        }}
                    />
                    : label
                }
            </div>
        </Html>
    );
};

export function Human({ onHotspotClick }) {
    const model = useLoader(
        GLTFLoader,
        '/models/stylized__female_blocking.glb',
        (loader) => {
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
            loader.setDRACOLoader(dracoLoader);
        }
    );

    const controlsRef = useRef();
    const { camera } = useThree();

    const floatSpeed = 0.8;
    const floatAmplitude = 0.015;

    useEffect(() => {
        if (model) {
            model.scene.traverse((child) => {
                if (child.isMesh) {
                    const newMaterial = new THREE.MeshPhongMaterial({
                        color: new THREE.Color('#8B7267'),
                        specular: new THREE.Color('#FFE4D6'),
                        shininess: 30,
                        skinning: true,
                        side: THREE.DoubleSide
                    });

                    child.material = newMaterial;
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            model.scene.rotation.x = -THREE.MathUtils.degToRad(10);
        }
    }, [model]);

    useEffect(() => {
        const floatEffect = () => {
            if (model) {
                const elapsedTime = Date.now() * 0.001 * floatSpeed;
                model.scene.position.y = -1 + Math.sin(elapsedTime) * floatAmplitude;
            }
            requestAnimationFrame(floatEffect);
        };
        floatEffect();
    }, [model]);

    const hotspots = [
        {
            position: [0.08, 0.5, 0],
            label: "🫁",
            name: "Poumons",
            isImage: false
        },
        {
            position: [0.14, 0.67, 0],
            label: "❤️",
            name: "Cœur",
            isImage: false
        },
        {
            position: [0.18, 0.29, 0],
            label: reinsIcon,
            name: "Reins",
            isImage: true
        }
    ];

    const handleHotspotClick = (hotspot) => {
        const cameraPosition = { x: hotspot.position[0], y: hotspot.position[1] + 1, z: hotspot.position[2] + 2 };
        const targetPosition = { x: hotspot.position[0], y: hotspot.position[1], z: hotspot.position[2] };

        gsap.to(camera.position, {
            x: cameraPosition.x,
            y: cameraPosition.y,
            z: cameraPosition.z,
            duration: 0.8,
            ease: "power2.inOut",
            onComplete: () => onHotspotClick(hotspot)
        });

        gsap.to(controlsRef.current.target, {
            x: targetPosition.x,
            y: targetPosition.y,
            z: targetPosition.z,
            duration: 0.8,
            ease: "power2.inOut"
        });
    };

    const resetView = () => {
        gsap.timeline()
            .to(camera.position, {
                x: 0,
                y: 0,
                z: 5,
                duration: 1.5,
                ease: "power2.inOut"
            })
            .to(controlsRef.current.target, {
                x: 0,
                y: 0,
                z: 0,
                duration: 1.5,
                ease: "power2.inOut"
            }, "<");
    };

    return (
        <Suspense fallback={null}>
            <group>
                <directionalLight position={[0, 2, 5]} intensity={1.2} castShadow />
                <pointLight position={[-3, 2, 2]} intensity={0.6} color="#FFE4D6" />
                <pointLight position={[3, 2, 2]} intensity={0.6} color="#FFE4D6" />
                <spotLight position={[0, 5, 0]} intensity={0.4} angle={Math.PI / 4} penumbra={1} decay={2} />

                <primitive
                    object={model.scene}
                    scale={[0.5, 0.5, 0.5]}
                    position={[0, -1, 0]}
                    onClick={resetView}
                />
                {hotspots.map((hotspot, index) => (
                    <HotspotButton
                        key={index}
                        position={hotspot.position}
                        label={hotspot.label}
                        onClick={() => handleHotspotClick(hotspot)}
                        isImage={hotspot.isImage}
                    />
                ))}
                <OrbitControls
                    ref={controlsRef}
                    enableZoom={false}
                    enablePan={false}
                    minPolarAngle={Math.PI / 3}
                    maxPolarAngle={Math.PI / 2}
                />
            </group>
        </Suspense>
    );
}
import React, { Suspense, useRef, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useAnimations } from '@react-three/drei';
import * as THREE from 'three';



export function Water() {
  const groupRef = useRef();
  // Charger le modèle
  const model = useLoader(
      GLTFLoader,
      '/models/free_ocean_wave_animation.glb',
      (loader) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);
      }
  );

  // Charger les animations
  const { animations } = model;
  const { actions, mixer } = useAnimations(animations, groupRef);

  // Modifier la couleur du matériau au chargement
  useEffect(() => {
    model.scene.traverse((child) => {
      if (child.isMesh) {
        if (child.material) {
          child.material.color = new THREE.Color(0x0846f6); 
          child.material.needsUpdate = true;
        }
      }
    });
  }, [model]);

  // Démarrer l'animation au montage
  useEffect(() => {
    if (actions) {
      actions[Object.keys(actions)[0]]?.play(); // Joue la première animation
    }
    return () => mixer?.stopAllAction(); // Arrête les animations lors du démontage
  }, [actions, mixer]);

  return (
      <Suspense fallback={null}>
        <group
            ref={groupRef}
            position={[0, -1, 0]}
            scale={[0.5, 0.5, 0.5]}
            rotation={[THREE.MathUtils.degToRad(85), 0, 0]} // Inclinaison de l'eau
        >
          <primitive object={model.scene} />
        </group>
      </Suspense>
  );
}

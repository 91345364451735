// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Home.css */
.home {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .home-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #282c34;
    color: white;
  }
  
  .home-title {
    flex: 1 1;
    text-align: center;
    margin: 0;
  }
  
  .home-score {
    position: absolute;
    right: 2.5%;
    font-size: 18px;
    margin: 0;
  }
  
  .home-body {
    height: 90%;
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA,aAAa;AACb;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,SAAO;IACP,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,SAAS;EACX;;EAEA;IACE,WAAW;IACX,WAAW;EACb","sourcesContent":["/* Home.css */\n.home {\n    display: flex;\n    height: 100%;\n    width: 100%;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n  \n  .home-header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 10%;\n    background-color: #282c34;\n    color: white;\n  }\n  \n  .home-title {\n    flex: 1;\n    text-align: center;\n    margin: 0;\n  }\n  \n  .home-score {\n    position: absolute;\n    right: 2.5%;\n    font-size: 18px;\n    margin: 0;\n  }\n  \n  .home-body {\n    height: 90%;\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

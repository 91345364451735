// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/bgCookie1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/images/bottomBgCookie1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-board {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100% 100%; /* Étire l'image pour remplir tout le conteneur */
    background-repeat: no-repeat; /* Empêche la répétition */
    background-position: center; /* Centre l'image */
    width: 100%;
    height: 100vh; /* Remplit la hauteur de la fenêtre */
}

body, html {
    margin: 0;
    padding: 0;
    overflow: hidden; /* Empêche les barres de défilement */
}
.bottomBg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: 100% 100%; /* Étire l'image pour remplir tout le conteneur */
    background-repeat: no-repeat; /* Empêche la répétition */
    background-position: center; /* Centre l'image */
    width: 100%;
    height: 100vh;
    z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/components/CookieClickerBryan.css"],"names":[],"mappings":"AAAA;IACI,yDAAuD;IACvD,0BAA0B,EAAE,iDAAiD;IAC7E,4BAA4B,EAAE,0BAA0B;IACxD,2BAA2B,EAAE,mBAAmB;IAChD,WAAW;IACX,aAAa,EAAE,qCAAqC;AACxD;;AAEA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB,EAAE,qCAAqC;AAC3D;AACA;IACI,yDAA6D;IAC7D,0BAA0B,EAAE,iDAAiD;IAC7E,4BAA4B,EAAE,0BAA0B;IACxD,2BAA2B,EAAE,mBAAmB;IAChD,WAAW;IACX,aAAa;IACb,YAAY;AAChB","sourcesContent":[".game-board {\n    background-image: url(\"../assets/images/bgCookie1.png\");\n    background-size: 100% 100%; /* Étire l'image pour remplir tout le conteneur */\n    background-repeat: no-repeat; /* Empêche la répétition */\n    background-position: center; /* Centre l'image */\n    width: 100%;\n    height: 100vh; /* Remplit la hauteur de la fenêtre */\n}\n\nbody, html {\n    margin: 0;\n    padding: 0;\n    overflow: hidden; /* Empêche les barres de défilement */\n}\n.bottomBg {\n    background-image: url(\"../assets/images/bottomBgCookie1.png\");\n    background-size: 100% 100%; /* Étire l'image pour remplir tout le conteneur */\n    background-repeat: no-repeat; /* Empêche la répétition */\n    background-position: center; /* Centre l'image */\n    width: 100%;\n    height: 100vh;\n    z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

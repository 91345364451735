// Home.js
import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <div className="home-header">
        <h1 className="home-title">Ocean Healer</h1>
        <div className="home-score">Achievement: 1/5</div>
      </div>
      <div className="home-body">
        <img
          className="human"
          src="https://png.pngtree.com/png-vector/20231018/ourmid/pngtree-smart-business-man-transparent-background-png-image_10309671.png"
          alt="Human"
        />
      </div>
    </div>
  );
}

export default Home;

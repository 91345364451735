import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import "./bouse_graphe.css";
import AutoSell from './autoSell';

Chart.register(...registerables);

function Bourse({ setPrixAction, vendreDechet, argentTotal, setArgentTotal }) {
  const [stockPrices, setStockPrices] = useState([]);
  const [timeLabels, setTimeLabels] = useState([]);
  const [isVisible, setIsVisible] = useState(true); // État pour la visibilité
  const [sellActif, setSellActif] = useState(false);
  const [prixSell, setPrixSell] = useState(1000);

  const simulateStockPrice = () => {
    const newPrice = (Math.random() * (0.75 - 0.25) + 0.25).toFixed(2);
    const currentTime = new Date().toLocaleTimeString();

    setStockPrices((prevPrices) => {
      const updatedPrices = [...prevPrices, newPrice];
      if (updatedPrices.length > 5) {
        updatedPrices.shift();
      }
      return updatedPrices;
    });

    setTimeLabels((prevLabels) => {
      const updatedLabels = [...prevLabels, currentTime];
      if (updatedLabels.length > 5) {
        updatedLabels.shift();
      }
      return updatedLabels;
    });

    // Mettre à jour le prix de l'action dans le composant parent
    setPrixAction(newPrice);
  };

  const acheterAutoSell = () => {
    if (argentTotal >= prixSell) {
      setSellActif(true);
      setArgentTotal(argentTotal - prixSell);
    } else {
      alert("Vous n'avez pas assez d'argent");
    }
  };

  useEffect(() => {
    let isMounted = true; // Ajouter un contrôle pour éviter les mises à jour après démontage

    simulateStockPrice();

    const interval = setInterval(() => {
      if (isMounted) {
        simulateStockPrice();
      }
    }, 3000);

    return () => {
      isMounted = false;
      clearInterval(interval); // Nettoyer l'intervalle lors du démontage
    };
  }, [argentTotal]);

  const chartData = {
    labels: timeLabels,
    datasets: [
      {
        label: 'Cours de l’action (€)',
        data: stockPrices,
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        color: "rgba(255, 255, 255, 1)",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Heure',
          color: 'rgba(255, 255, 255, 1)',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        grid: {
          color: 'rgba(255, 255, 255, 1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 1)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Prix (€)',
          color: 'rgba(255, 255, 255, 1)',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        grid: {
          color: 'rgba(255, 255, 255, 1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 1)',
          min: 0.25,
          max: 0.75,
          stepSize: 0.25,
        },
      },
    },
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {/* Bouton pour afficher/masquer la div du graphique */}
      <button onClick={toggleVisibility} className="button">
        {isVisible ? "Désactiver Auto Sell" : "Activer Auto Sell"}
      </button>

      {/* Affichage conditionnel du graphique */}
      {isVisible && (
        <div className="stock-chart">
          <h1>Cours de l’action des déchets</h1>
          <p>Prix actuel: {stockPrices.length > 0 ? stockPrices[stockPrices.length - 1] : "En attente..."}</p>
          <div className="chart-container">
            <Line data={chartData} options={chartOptions} />
          </div>
          {sellActif ? (
            <AutoSell 
              bourseValue={stockPrices.length > 0 ? stockPrices[stockPrices.length - 1] : 0} 
              vendreDechet={vendreDechet} 
            />
          ) : (
            <button onClick={acheterAutoSell} className="toggle-button">
              Acheter Auto Sell ({prixSell} €)
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default Bourse;

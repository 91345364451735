import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReturnToHome = () => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/');
  };

  return (
    <button onClick={handleReturnHome}>
      Retour à l'accueil
    </button>
  );
};

export default ReturnToHome;

import React, { useState, useEffect } from "react";
import "./cookie.css";
import { giveAchievement } from "../../components/achievment/achievement";
import { useNavigate } from 'react-router-dom';
import ReturnToHome from '../../components/retourAceuil';

function MarineClicker() {
  // States
  const [litres, setLitres] = useState(0); // Monnaie générée
  const [seaLevel, setSeaLevel] = useState(0); // Niveau de la mer (%)
  const [timeLeft, setTimeLeft] = useState(60); // Temps restant en secondes
  const [position, setPosition] = useState({ x: -10, y: 50 }); // Position initiale (x et y)
  const [isVertical, setIsVertical] = useState(false); // Mouvement vertical ou horizontal
  const objectif = litres >= 1;
  const navigate = useNavigate();
  const objectif2 = litres >= 10;
  if (objectif){
    giveAchievement(4);

  }
  if (objectif2){
    navigate('/');
  }

  const bpm = 75; // Battements par minute fixes
  const increment = 100 / bpm; // Augmentation par clic en fonction des BPM

  // Déplacement dynamique du bouton
  useEffect(() => {
    const moveButton = setInterval(() => {
      setPosition((prev) => {
        if (!isVertical) {
          // Mouvement horizontal
          const speed = 2 + seaLevel / 50; // Vitesse augmente avec le niveau de la mer
          const nextX = prev.x + speed;
          return { x: nextX > 110 ? -10 : nextX, y: prev.y }; // Repart à gauche après être sorti
        } else {
          // Mouvement vertical
          const speed = 2 + seaLevel / 50; // Vitesse augmente avec le niveau de la mer
          const nextY = prev.y + speed;
          return { x: prev.x, y: nextY > 90 ? 10 : nextY }; // Repart en haut après être sorti
        }
      });
    }, 30); // Vitesse de mise à jour

    return () => clearInterval(moveButton);
  }, [seaLevel, isVertical]);

  // Décrémentation du timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          window.location.reload(); // Rafraîchit la page si le temps est écoulé
        }
        if (prev === 10) {
          setIsVertical(true); // Passe au mouvement vertical dans les 10 dernières secondes
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Clic sur le bouton
  const handleClick = () => {
    if (seaLevel < 100) {
      setLitres(litres + 1);
      setSeaLevel(seaLevel + increment); // Augmente le niveau de la mer
    }
  };

  return (
    <div
      className="marine-container"
      style={{
        backgroundSize: "100% 100%",
        backgroundImage: `linear-gradient(to top, #00bfff ${seaLevel}%, #87ceeb ${seaLevel}%)`,
      }}
    >
      <ReturnToHome/>
      <p className="para">Litres accumulés : {litres}</p>
      <p className="para">Niveau de la mer : {seaLevel.toFixed(1)}%</p>
      <p className="para">Temps restant : {timeLeft} secondes</p>

      {/* Bouton en mouvement */}
      {seaLevel < 100 ? (
        <button
          onClick={handleClick}
          className="marine-button"
          style={{
            left: `${position.x}%`,
            top: `${position.y}%`,
            position: "absolute",
          }}
        >
          🌊 Cliquez ici !
        </button>
      ) : (
        <div className="game-over">
          <h2>Vous avez réussi à remplir la mer !</h2>
        </div>
      )}
    </div>
  );
}

export default MarineClicker;
import React, { useState, useEffect } from 'react';
import {giveAchievement} from "../achievment/achievement";

const MovingLogo = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 }); 
    const [isEntering, setIsEntering] = useState(false); 
    const [clickCount, setClickCount] = useState(0); 
    const [achievementUnlocked, setAchievementUnlocked] = useState(false); 
    const [showAchievementMessage, setShowAchievementMessage] = useState(false); 

    // Fonction pour déplacer le logo à un bord aléatoire de la page
    const moveLogoIn = () => {
        setIsEntering(true); 
        const side = Math.floor(Math.random() * 4); 
        let newPosition = {};

        switch (side) {
            case 0: // Gauche
                newPosition = { x: -20, y: Math.random() * 90 }; 
                break;
            case 1: // Droite
                newPosition = { x: 120, y: Math.random() * 90 }; 
                break;
            case 2: // Haut
                newPosition = { x: Math.random() * 90, y: -20 }; 
                break;
            case 3: // Bas
                newPosition = { x: Math.random() * 90, y: 120 }; 
                break;
            default:
                break;
        }

        setPosition(newPosition);

       
        setTimeout(() => {
            setIsEntering(false); 
        }, 3000); // 
    };

    // sortir et rentrer le logo  sur la page toutes les 1 à 2 secondes
    useEffect(() => {
        const randomDisappearTime = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000; // Entre 1 et 2 secondes
        const disappearInterval = setInterval(() => {
            moveLogoIn(); 
        }, randomDisappearTime);

        return () => clearInterval(disappearInterval); 
    }, []);

    // Empêcher le défilement de la page
    useEffect(() => {
        document.body.style.overflow = 'hidden'; 
        return () => {
            document.body.style.overflow = 'auto'; 
        };
    }, []);

    // Fonction de gestion du clic sur le logo
    const handleClick = () => {
        setClickCount(prevCount => prevCount + 1); // Incrémente le compteur de clics

        // Débloquer l'achievement si l'utilisateur a cliqué 5 fois
        if (clickCount + 1 === 1) {
            setAchievementUnlocked(true); 
            giveAchievement(3);
        }
    };

    return (
        <div>
            {/* Logo avec gestion du clic */}
            <img
                src="/Logo_lyreco.jpeg" // Chemin vers l'image dans le dossier `public`
                alt="Logo Lyreco"
                style={{
                    top: `${position.y}vh`,
                    left: `${position.x}vw`,
                    position: 'absolute',
                    width: '80px',
                    height: 'auto',
                    backgroundColor: 'transparent',
                    border: '2px solid #000',
                    borderRadius: '5px',
                    cursor: 'pointer', 
                    opacity: 1, // Le logo reste visible
                    transition: 'top 3s ease, left 3s ease', 
                    userSelect: 'none', 
                    pointerEvents: 'auto', 
                    zIndex: 1000, 
                }}
                onClick={handleClick} 
            />
        </div>
    );
};

export default MovingLogo;
